<template>
  <div>
    <!-- 资讯头图 -->
    <div class="NewsDetails_banner">
      <img src="@/assets/img/News/banner-news.png" />
    </div>
    <div class="NewsDetails">
      <!-- 资讯内容 -->
      <div class="NewsDetails_content" ref="list">
        <!-- 标题与发布时间 -->
        <div>
          <div class="NewsDetails_contentTitle">
            {{$route.query.title}}
          </div>
          <div class="NewsDetails_contentInfo">
            <div>公司资讯</div>
            <div></div>
            <div>发布时间 ：{{$route.query.time}}</div>
            <div @click="goBack()">返回资讯列表</div>
          </div>
        </div>
        <div v-html="$route.query.content"></div>
      </div>
      <!-- 资讯列表 -->
      <!-- <div
        class="NewsDetails_list"
        :style="
          sidebarTop <= 88
            ? 'position: fixed;top: 88px; margin-left: 900px;'
            : 'position: absolute;top: 0;right: 0;'
        "> -->
        <!-- 公司资讯 -->
        <!-- <div>
          <div>公司资讯</div>
          <div>
            <div v-for="(item, index) in NewsCompanyList" :key="index">
              {{ item.title }}
            </div>
          </div>
        </div> -->
        <!-- 行业资讯 -->
        <!-- <div>
          <div>行业资讯</div>
          <div>
            <div v-for="(item, index) in NewsIndustryList" :key="index">
              {{ item.title }}
            </div>
          </div>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 公司资讯列表
      NewsCompanyList: [
        {
          title: "喜讯！热烈祝贺我公司获得“高新技术企业证书”",
          time: "2020-03-19 09:38",
          info: "我公司获得2019年第一批高新技术企业证书",
        },
      ],
      // 行业资讯列表
      NewsIndustryList: [
        {
          title: "机器视觉系统就是指用计算机来保持人的視覺作用",
          time: "2019-12-09 12:05",
          info:
            "计算机设备设备替代人的眼睛来做精确测量和分辨，不但工作效能快，精准度也更高，能更强的考虑现如今工业化生产要求，合理操纵产品成本，提升公司盈利，备受各制造行业客户的亲睐。许多客户针对机器视觉检测设备都有一定的掌握，但针对它实际可用以什么制造行业并非很清晰，下边微林手机软件高新科技就为大伙儿梳理详细介绍。",
        },
        {
          title: "机器视觉在工业化生产上的运用",
          time: "2020-01-04 11:47",
          info:
            "设计方案到各式各样的检测，生产制造牢靠及零件鉴别的运用，在传统式的人工检测监控中，一般人的眼睛没法不断好的工作，别的物理学控制器也没办法充分发挥到功效。",
        },
        {
          title: "机器视觉系统对光源条件十分关键",
          time: "2019-12-09 11:54",
          info:
            "在机器视觉系统中，光源具备十分关键的功效，选光源及衍射光栅设计方案的成功与失败是决策系统软件成功与失败的主要要素。一个适合的光源能够使图像中的总体目标特点与背景图信息内容获得最好分离出来，进而大幅度降低图像解决的难度系数，提升系统软件的可靠性和可信性。",
        },
      ],
      // 侧边栏顶固距离
      sidebarTop: null,
    };
  },
  mounted() {
    // 滚动条的获取
    window.addEventListener("scroll", this.handleScrollx);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollx);
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 滚动条
    handleScrollx() {
      this.$nextTick(() => {
        this.sidebarTop = this.$refs.list.getBoundingClientRect().top;
      })
    },
  },
};
</script>

<style scoped>
.NewsDetails {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewsDetails_banner img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: flex;
}
.NewsDetails_content {
  width: 800px;
  margin: 100px 0;
}
.NewsDetails_content img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.NewsDetails_contentTitle {
  color: #323232;
  font-size: 30px;
  color: #323232;
}
.NewsDetails_contentInfo {
  color: #47B0B4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 40px 0;
}
.NewsDetails_contentInfo > div:nth-of-type(2) {
  border: 1px solid #a0eaec;
  height: 16px;
  margin: 0 20px;
}
.NewsDetails_contentInfo > div:nth-of-type(4) {
  margin: 0 20px;
  color: #b3b3b3;
  cursor: pointer;
}
.NewsDetails_list {
  width: 300px;
  background-color: #fff;
}
.NewsDetails_list > div > div:nth-of-type(1) {
  color: #47b0b4;
  font-size: 20px;
  padding: 12px 10px;
  box-sizing: border-box;
  font-weight: 600;
}
.NewsDetails_list > div > div:nth-of-type(2) {
  border-top: 1px solid #a0eaec;
  border-bottom: 1px solid #a0eaec;
  padding: 10px;
}
.NewsDetails_list > div > div:nth-of-type(2) > div {
  height: 20px;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>